var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('apexchart', {
    staticClass: "mb-4",
    attrs: {
      "type": "bar",
      "height": "350",
      "options": _vm.chartOptions,
      "series": _vm.consumptionByCategory
    }
  }), _c('div', {
    staticClass: "widget-grid mb-5"
  }, [_c('div', {
    staticClass: "info-box d-flex align-items-center px-4 py-3"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('p', {
    staticClass: "font-size-h2 mb-1"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.categorySummary.landfill, 2)) + " " + _vm._s(_vm.units))]), _c('p', {
    staticClass: "text-muted font-w600 mb-0"
  }, [_vm._v("Total waste to landfill")]), _c('p', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.startDate.format('Do MMM YYYY')) + " to " + _vm._s(_vm.endDate.format('Do MMM YYYY')))])]), _c('i', {
    staticClass: "fad text-warning fa-dumpster fa-2x ml-2"
  })]), _c('div', {
    staticClass: "info-box d-flex align-items-center px-4 py-3"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('p', {
    staticClass: "font-size-h2 mb-1"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.categorySummary.recyclables, 2)) + " " + _vm._s(_vm.units))]), _c('p', {
    staticClass: "text-muted font-w600 mb-0"
  }, [_vm._v("Total waste recycled")]), _c('p', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.startDate.format('Do MMM YYYY')) + " to " + _vm._s(_vm.endDate.format('Do MMM YYYY')))])]), _c('i', {
    staticClass: "fad text-info fa-recycle fa-2x ml-2"
  })]), _c('div', {
    staticClass: "info-box d-flex align-items-center px-4 py-3"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('p', {
    staticClass: "font-size-h2 mb-1"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.categorySummary.compost, 2)) + " " + _vm._s(_vm.units))]), _c('p', {
    staticClass: "text-muted font-w600 mb-0"
  }, [_vm._v("Total waste composted")]), _c('p', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.startDate.format('Do MMM YYYY')) + " to " + _vm._s(_vm.endDate.format('Do MMM YYYY')))])]), _c('i', {
    staticClass: "fad text-success fa-apple-core fa-2x ml-2"
  })]), _c('div', {
    staticClass: "info-box d-flex align-items-center px-4 py-3"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('p', {
    staticClass: "font-size-h2 mb-1"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.categorySummary.efw, 2)) + " " + _vm._s(_vm.units))]), _c('p', {
    staticClass: "text-muted font-w600 mb-0"
  }, [_vm._v("Total waste EfW")]), _c('p', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.startDate.format('Do MMM YYYY')) + " to " + _vm._s(_vm.endDate.format('Do MMM YYYY')))])]), _c('i', {
    staticClass: "fad text-danger fa-fire fa-2x ml-2"
  })]), _c('div', {
    staticClass: "info-box d-flex align-items-center px-4 py-3"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('p', {
    staticClass: "font-size-h2 mb-1"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.categorySummary.total, 2)) + " " + _vm._s(_vm.units))]), _c('p', {
    staticClass: "text-muted font-w600 mb-0"
  }, [_vm._v("Total waste")]), _c('p', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm.startDate.format('Do MMM YYYY')) + " to " + _vm._s(_vm.endDate.format('Do MMM YYYY')))])]), _c('i', {
    staticClass: "fad text-success fa-2x ml-2"
  })])]), _c('SectionTitle', {
    staticClass: "mb-5"
  }, [_vm._v("Summary Table")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-6"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.consumptionByCategory, function (category) {
    return _c('tr', {
      key: category.name
    }, [_c('td', [_vm._v(_vm._s(category.name))]), _c('td', [_vm._v(_vm._s(_vm._f("numberFormat")(category.data.reduce(function (sum, v) {
      return sum + v;
    }, 0), 2)) + " " + _vm._s(_vm.units))])]);
  }), _c('tr', [_c('th', [_vm._v("Total")]), _c('th', [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.categorySummary.total, 2)) + " " + _vm._s(_vm.units))])])], 2)])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Category")]), _c('th', [_vm._v("Amount")])])]);
}]

export { render, staticRenderFns }