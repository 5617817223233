<template>
  <div data-cy="asset-accounts-consumptions-page">
    <TabTitle class="mb-4" icon="fa-chart-column" action-position="right">
      <template>{{ account.type !== 'solar' ? 'Consumption' : 'Generation' }}</template>
      <template #sub-title>View {{ account.type !== 'solar' ? 'consumption' : 'generation' }} data relating to this account.</template>
    </TabTitle>

    <div class="bg-lighter rounded-md px-4 py-3 mb-4">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center mr-3 border-right pr-3">
          <div class="form-static-text pr-3">Date Range</div>
          <FormItem
            id="dateRange"
            v-model="filters.dateRange"
            type="dateRangePicker"
            :disabled="loadingAction.getConsumptions"
            class="mb-0"
            date-format="DD/MM/YYYY"
            style="width: 200px"
            @input="() => refresh()"
          />
        </div>
        <div class="d-flex align-items-center mr-3 border-right pr-3">
          <div class="form-static-text pr-3">Granularity</div>
          <FormItem
            id="granularity"
            v-model="filters.granularity"
            class="mb-0"
            type="select"
            :options="[
              { label: 'Half-hourly', value: 'halfhourly' },
              { label: 'Hourly', value: 'hourly' },
              { label: 'Daily', value: 'daily' },
              { label: 'Weekly', value: 'weekly' },
              { label: 'Monthly', value: 'monthly' },
              { label: 'Quarterly', value: 'quarterly' },
              { label: 'Annually', value: 'yearly' }
            ]"
            :disabled="loadingAction.getConsumptions"
            @input="() => refresh()"
          />
        </div>
        <div class="d-flex align-items-center mr-3 border-right pr-3">
          <div class="form-static-text pr-3">Source</div>
          <FormItem
            id="source"
            v-model="filters.source"
            class="mb-0"
            type="select"
            :options="[
              { label: 'Combined', value: 'combined' },
              { label: 'Invoice', value: 'invoice' },
              { label: 'Readings', value: 'reading' },
              { label: 'Custom', value: 'custom' },
              { label: 'HH Data', value: 'hh' }
            ]"
            :disabled="loadingAction.getConsumptions"
            @input="() => refresh()"
          />
        </div>
        <div class="d-flex flex-grow-1 align-items-center justify-content-end">
          <a href="#" class="font-w600" @click.prevent="showMoreFilters = !showMoreFilters"
            >{{ showMoreFilters ? 'Hide' : 'More' }} Filters
            <i class="fa fa-chevron-down ml-1" :class="showMoreFilters ? 'fa-chevron-right' : 'fa-chevron-down'"></i
          ></a>
        </div>
      </div>
      <div v-if="showMoreFilters" class="d-flex align-items-center mt-3">
        <div class="d-flex align-items-center mr-3 border-right pr-3">
          <div class="form-static-text pr-3">Highlight</div>
          <FormItem
            id="highlight"
            v-model="filters.highlight"
            class="mb-0"
            type="select"
            placeholder="Select..."
            :options="[
              { label: 'None', value: null },
              { label: 'Source', value: 'source' },
              { label: 'Estimated', value: 'estimated' }
            ]"
            :config="{ allowClear: true }"
            :disabled="loadingAction.getConsumptions"
            @input="() => refresh()"
          />
        </div>
        <div class="d-flex align-items-center mr-3 border-right pr-3">
          <div class="form-static-text pr-3">Graph Type</div>
          <a
            href="#"
            class="font-w600 px-3 py-1"
            :class="{ 'bg-primary text-white border rounded-md': filters.chartType === 'bar' }"
            @click.prevent="filters.chartType = 'bar'"
            ><i class="fa-solid fa-chart-column mr-1"></i> Bar</a
          >
          <a
            href="#"
            class="font-w600 px-3 py-1"
            :class="{ 'bg-primary text-white border rounded-md': filters.chartType === 'line' }"
            @click.prevent="filters.chartType = 'line'"
            ><i class="fa-solid fa-chart-line mr-1"></i> Line</a
          >
        </div>
      </div>
    </div>

    <div v-if="account.type !== 'waste'">
      <div v-if="analyticsLoading.getAnalytics['consumption']" class="mb-4">
        <Spinner />
      </div>

      <Chart v-else-if="analytics['consumption']?.data?.length" class="mb-4" style="height: 500px" :option="consumptionChart" />

      <div v-else class="alert alert-warning" role="alert">
        <p class="mb-0">No consumption data found{{ Object.keys(filters).some(key => filters[key]) ? ' within the selected filters' : '' }}.</p>
      </div>

      <!-- There is no initial load for this object entry, so component is giving type error -->
      <DefaultSummary
        v-if="analytics['consumption']"
        class="mb-4"
        :account="account"
        :type="account.type"
        :data="analytics['consumption']"
        :loading="analyticsLoading.getAnalytics['consumption']"
        :options="{ filters }"
      />
    </div>

    <!-- WASTE ONLY -->
    <div v-else>
      <div v-if="consumptions.length > 0 && !loadingAction.getConsumptions">
        <WasteSummary v-if="account.type === 'waste'" :account="account" :consumptions="consumptions" :options="{ filters }" />
        <DefaultSummary v-else :account="account" :type="account.type" :data="analytics['consumption'] || false" :options="{ filters }" />
      </div>

      <div v-else-if="loadingAction.getConsumptions" class="mb-4">
        <Spinner />
      </div>

      <div v-else class="alert alert-warning" role="alert">
        <p class="mb-0">No consumption data found{{ Object.keys(filters).some(key => filters[key]) ? ' within the selected filters' : '' }}.</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Chart from '@/components/echarts/Chart';

import FormItem from '@/components/FormItem';
import Spinner from '@/components/SpinnerLogo';
import TabTitle from '@/components/base/TabTitle';
import DefaultSummary from '@/components/consumption/DefaultSummary';
import WasteSummary from '@/components/consumption/WasteSummary';

import { chartConsumptionOptions, granularityFormatMap } from '../../../lib/consumption';

export default {
  name: 'AssetAccountOverview',
  components: {
    Chart,
    DefaultSummary,
    WasteSummary,
    FormItem,
    Spinner,
    TabTitle
  },
  data() {
    return {
      showAdd: false,
      initialLoad: true,
      importStatus: 'pending',
      form: {
        timestamp: moment().format('DD/MM/YYYY HH:mm'),
        value: ''
      },
      filters: {
        dateRange: [moment().subtract(90, 'days').startOf('day').toDate(), moment().subtract(1, 'day').endOf('day').toDate()],
        granularity: 'daily',
        source: 'combined',
        highlight: null,
        chartType: 'bar'
      },
      showMoreFilters: true
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      consumptions: 'account/consumptions',
      account: 'asset/selectedAccount',
      loadingAction: 'account/loadingAction',
      errorAction: 'account/errorAction',
      analytics: 'analytics/analytics',
      analyticsLoading: 'analytics/loadingAction'
    }),
    consumptionStartDate() {
      return moment(this.filters.dateRange[0], 'DD/MM/YYYY');
    },
    consumptionEndDate() {
      return moment(this.filters.dateRange[1], 'DD/MM/YYYY');
    },
    consumptionChart() {
      const data = this.analytics['consumption']?.data || [];

      const baseOptions = {};

      const baseSeriesOptions = {
        type: this.filters.chartType
      };

      if (this.filters.chartType === 'line') {
        baseSeriesOptions.smooth = false;
      }

      // Align columns with dates correctly.
      // A quarterly column should not be centered on 1st Jan, but should span from 1st Jan to 31st Mar.
      const totalConsumptionSeries = {
        ...baseSeriesOptions,
        name: 'Consumption',
        data: data.map(m => [m.date, !m.isMissing ? m.consumption : null])
      };

      console.log(data);

      const actualConsumptionSeries = {
        ...baseSeriesOptions,
        name: 'Actual',
        data: data.map(m => {
          return [m.date, !m.isMissing ? m.consumption - m.estimatedConsumption : null];
        })
      };

      const estimatedConsumptionSeries = {
        ...baseSeriesOptions,
        name: 'Estimated',
        data: data.map(m => [m.date, m.estimatedConsumption])
      };

      let series = [];

      if (this.filters.highlight === 'source') {
        const invoiceConsumptionSeries = {
          ...baseSeriesOptions,
          name: 'Invoices',
          data: data.map(m => {
            const invoice = m.combinedBreakdown?.invoice || null;
            return [m.date, invoice];
          })
        };

        const hhConsumptionSeries = {
          ...baseSeriesOptions,
          name: 'Half Hourly',
          data: data.map(m => {
            const hh = m.combinedBreakdown?.hh || null;
            return [m.date, hh];
          })
        };

        const customConsumptionSeries = {
          ...baseSeriesOptions,
          name: 'Custom',
          data: data.map(m => {
            const custom = m.combinedBreakdown?.custom || null;
            return [m.date, custom];
          })
        };

        const readingConsumptionSeries = {
          ...baseSeriesOptions,
          name: 'Readings',
          data: data.map(m => {
            const reading = m.combinedBreakdown?.reading || null;
            return [m.date, reading];
          })
        };

        series = [
          { ...invoiceConsumptionSeries, stack: this.filters.chartType === 'bar' ? 'a' : null },
          { ...hhConsumptionSeries, stack: this.filters.chartType === 'bar' ? 'a' : null },
          { ...customConsumptionSeries, stack: this.filters.chartType === 'bar' ? 'a' : null },
          { ...readingConsumptionSeries, stack: this.filters.chartType === 'bar' ? 'a' : null }
        ];
        baseOptions.legend = {};
      } else if (this.filters.highlight === 'estimated') {
        series = [
          { ...estimatedConsumptionSeries, stack: this.filters.chartType === 'bar' ? 'a' : null },
          { ...actualConsumptionSeries, stack: this.filters.chartType === 'bar' ? 'a' : null }
        ];
        baseOptions.legend = {};
      } else {
        series = [totalConsumptionSeries];
      }

      return {
        ...chartConsumptionOptions,
        ...baseOptions,
        color: ['#65C198', '#1F303D', '#F25F5C', '#FFE066', '#1B98E0'],
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            console.log(params);
            const s = params
              .map(param => {
                const series = param.seriesName.toLocaleString();
                const value = param.value[1] !== null ? param.value[1].toLocaleString() : null;
                const valueHtml = value
                  ? `<strong>${value}</strong> ${this.account.type === 'water' ? 'm<sup>3</sup>' : 'kWh'}`
                  : '<strong>No data</strong>';
                return `<i class="fa fa-circle fa-fw fa-sm mr-1" style="color: ${param.color}"></i>${series}: ${valueHtml}`;
              })
              .join('<br />');

            // axisValue is already in UTC
            const date = moment(params[0].axisValue).format(granularityFormatMap[this.filters.granularity].format);

            return `<strong>${date}</strong><br /><br />${s}`;
          },
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        xAxis: {
          useUTC: true,
          boundaryGap: granularityFormatMap[this.filters.granularity].type === 'category',
          type: granularityFormatMap[this.filters.granularity].type,
          minInterval: granularityFormatMap[this.filters.granularity].minTick,
          min: data.startDate,
          max: data.endDate,
          ...(granularityFormatMap[this.filters.granularity].type === 'category'
            ? {
                data: data.map(m => m.date),
                axisLabel: {
                  fontWeight: 'bold',
                  formatter: value => moment(value).format(granularityFormatMap[this.filters.granularity].format)
                }
              }
            : {})
        },
        yAxis: {
          name: this.account.type === 'water' ? 'm3' : 'kWh',
          type: 'value'
        },
        series
      };
    }
  },
  async mounted() {
    this.refresh();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      getConsumptions: 'account/getConsumptions',
      getAnalyticsV2: 'analytics/getAnalyticsV2'
    }),
    ...mapMutations({
      reset: 'account/RESET_STATE'
    }),
    async refresh() {
      const params = {
        startDate: this.consumptionStartDate.format('YYYY-MM-DD'),
        endDate: this.consumptionEndDate.format('YYYY-MM-DD'),
        granularity: this.filters.granularity,
        source: this.filters.source
      };

      if (this.account.type === 'waste') {
        params.$select = 'category,type,unit';
        await this.getConsumptions({ data: { params }, accountId: this.account._id });
      } else {
        this.getAnalyticsV2({
          id: 'consumption',
          params: {
            id: 'consumption',
            ...params,
            dataType: 'account',
            accountId: this.account._id,
            detailedSource: true
          }
        });
      }
    }
  }
};
</script>
