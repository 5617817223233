<template>
  <div>
    <!-- <Chart :options="chartData" class="mb-4" /> -->

    <div class="widget-grid">
      <div class="d-flex flex-column rounded-md bg-lighter px-4 py-3">
        <div class="mb-1" :class="{ 'placeholder placeholder-inline': loading }">
          <span class="font-size-h2 mr-2">{{ data.totalConsumption | numberFormat(2, 2) }}</span>
          <span class="font-size-h5 text-muted font-w600">
            <span v-if="type !== 'water'">kWh</span><span v-else>m<sup>3</sup></span>
          </span>
        </div>
        <div class="d-flex">
          <div class="flex-grow-1" :class="{ placeholder: loading }">
            <p class="text-muted font-w600 mb-0">Total {{ type !== 'solar' ? 'Consumption' : 'Generation' }}</p>
            <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
          </div>
          <div class="mt-2 pl-2">
            <i class="fad fa-meter fa-2x"></i>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column rounded-md bg-lighter px-4 py-3">
        <div class="mb-1" :class="{ 'placeholder placeholder-inline': loading }">
          <span class="font-size-h2 mr-2">{{ maxDemand | numberFormat(2) }}</span>
          <span class="font-size-h5 text-muted font-w600">
            <span v-if="type !== 'water'">kW</span><span v-else>m<sup>3</sup></span>
          </span>
        </div>
        <div class="d-flex">
          <div class="flex-grow-1" :class="{ placeholder: loading }">
            <p class="text-muted font-w600 mb-0">Max. {{ type !== 'solar' ? ' Demand' : ' Daily Generation' }}</p>
            <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
          </div>
          <div class="mt-2 pl-2">
            <i class="fad fa-arrows-up-to-line fa-2x"></i>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column rounded-md bg-lighter px-4 py-3">
        <div class="mb-1" :class="{ 'placeholder placeholder-inline': loading }">
          <span class="font-size-h2 mr-2">{{ minDemand | numberFormat(2) }}</span>
          <span class="font-size-h5 text-muted font-w600">
            <span v-if="type !== 'water'">kW</span><span v-else>m<sup>3</sup></span>
          </span>
        </div>
        <div class="d-flex">
          <div class="flex-grow-1" :class="{ placeholder: loading }">
            <p class="text-muted font-w600 mb-0">Min. {{ type !== 'solar' ? ' Demand' : ' Daily Generation' }}</p>
            <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
          </div>
          <div class="mt-2 pl-2">
            <i class="fad fa-arrows-down-to-line fa-2x"></i>
          </div>
        </div>
      </div>

      <!-- <div class="info-box d-flex align-items-center px-4 py-3">
        <div class="flex-grow-1">
          <p class="font-size-h2 mb-1">
            {{ minDemand | numberFormat(2) }} <span v-if="type !== 'water'">kW</span><span v-else>m<sup>3</sup></span>
          </p>
          <p v-if="isHalfHourly" class="text-muted font-w600 mb-0">
            Min. {{ isHalfHourly ? 'Hourly' : '' }}{{ type !== 'solar' ? ' Demand' : ' Daily Generation' }}
          </p>
          <p v-else class="text-muted font-w600 mb-0">Min. {{ type !== 'solar' ? ' Demand' : ' Daily Generation' }}</p>
          <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
        </div>
        <i class="fad fa-chart-line-down fa-2x ml-2"></i>
      </div> -->

      <!-- <div class="info-box d-flex align-items-center px-4 py-3">
        <div class="flex-grow-1">
          <p class="font-size-h2 mb-1">
            <span v-if="estimateData.length > 0">{{ actualVsEstimate }}%</span>
            <span v-else>N/A <small class="text-muted">(No estimated data)</small></span>
          </p>
          <p class="text-muted font-w600 mb-0">Estimated (vs Actual)</p>
          <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
        </div>
        <i class="fad fa-pen-field fa-2x ml-2"></i>
      </div> -->
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'DefaultSummary',
  props: {
    data: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    account: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    // isHalfHourly() {
    //   return this.consumptions.length > 1 && Math.abs(moment(this.consumptions[0].timestamp).diff(this.consumptions[1].timestamp, 'minutes')) < 60;
    // },
    startDate() {
      return moment(this.options.filters.dateRange[0], 'DD/MM/YYYY');
    },
    endDate() {
      return moment(this.options.filters.dateRange[1], 'DD/MM/YYYY');
    },
    // consumptionData() {
    //   return this.consumptions.map(c => [moment.utc(c.timestamp).valueOf(), c.value]);
    // },
    maxDemand() {
      console.log(this.options.filters.granularity);
      if (!this.data.data || this.data.data.length === 0) return 0;
      const peakConsumption = Math.max(...this.data.data.map(a => a.consumption));

      // Convert from kWh to kW
      if (this.options.filters.granularity === 'halfhourly') {
        return peakConsumption / 0.5;
      } else if (this.options.filters.granularity === 'daily') {
        return peakConsumption / 24;
      } else if (this.options.filters.granularity === 'monthly') {
        return peakConsumption / 24 / 30;
      } else if (this.options.filters.granularity === 'yearly') {
        return peakConsumption / 24 / 365;
      } else if (this.options.filters.granularity === 'weekly') {
        return peakConsumption / 24 / 7;
      }

      return peakConsumption;
    },
    minDemand() {
      if (!this.data.data || this.data.data.length === 0) return 0;
      const peakConsumption = Math.min(...this.data.data.map(a => a.consumption));

      // Convert from kWh to kW
      if (this.options.filters.granularity === 'halfhourly') {
        return peakConsumption / 0.5;
      } else if (this.options.filters.granularity === 'daily') {
        return peakConsumption / 24;
      } else if (this.options.filters.granularity === 'monthly') {
        return peakConsumption / 24 / 30;
      } else if (this.options.filters.granularity === 'yearly') {
        return peakConsumption / 24 / 365;
      } else if (this.options.filters.granularity === 'weekly') {
        return peakConsumption / 24 / 7;
      }

      return peakConsumption;
    },
    // actualVsEstimate() {
    //   const actual = this.consumptions.filter(c => c.readType !== 'E').reduce((sum, c) => sum + c.value, 0);
    //   const estimate = this.consumptions.filter(c => c.readType === 'E').reduce((sum, c) => sum + c.value, 0);

    //   return ((estimate / (actual + estimate)) * 100).toFixed(2);
    // },
    // estimateData() {
    //   return this.consumptions.filter(c => c.readType === 'E').map(c => [moment.utc(c.timestamp).valueOf(), c.value]);
    // },
    // actualData() {
    //   return this.consumptions.filter(c => c.readType !== 'E').map(c => [moment.utc(c.timestamp).valueOf(), c.value]);
    // },
    title() {
      const isOneDay = moment(this.startDate).isSame(this.endDate, 'day');

      let title = `${this.type !== 'solar' ? 'Consumption' : 'Generation'} data`;

      if (isOneDay) {
        title += ` on ${this.startDate.format('Do MMM YYYY')}`;
      } else {
        title += ` from ${this.startDate.format('Do MMM YYYY')} to ${this.endDate.format('Do MMM YYYY')}`;
      }

      return title;
    }
    // chartData() {
    //   let series = [
    //     {
    //       type: this.consumptions.length > 240 ? 'line' : 'column',
    //       data: this.consumptionData,
    //       name: `${this.type !== 'solar' ? 'Consumption' : 'Generation'} Data`
    //     },
    //     {
    //       type: 'spline',
    //       data: this.average,
    //       name: `Daily ${this.type !== 'solar' ? 'Consumption' : 'Generation'} Average`
    //     }
    //   ];

    //   if (this.options.filters.type === 'actualVsEstimate') {
    //     series = [
    //       {
    //         type: this.consumptions.length > 240 ? 'line' : 'column',
    //         data: this.consumptionData,
    //         zones: this.consumptions.map(c => ({ value: moment.utc(c.timestamp).valueOf(), color: c.readType === 'E' ? '#ffd15e' : '#3c5c61' })),
    //         zoneAxis: 'x',
    //         name: 'Actual',
    //         color: '#3c5c61'
    //       },
    //       {
    //         data: [],
    //         name: 'Estimate',
    //         color: '#ffd15e'
    //       },
    //       {
    //         type: 'spline',
    //         data: this.average,
    //         name: `Daily ${this.type !== 'solar' ? 'Consumption' : 'Generation'} Average`,
    //         color: '#4d9575'
    //       }
    //     ];
    //   }

    //   return {
    //     ...this.defaultChartOptions,
    //     chart: {
    //       zoomType: 'x',
    //       style: {
    //         fontFamily: 'Inter'
    //       }
    //     },
    //     title: {
    //       text: this.title,
    //       style: {
    //         fontSize: 20,
    //         fontWeight: 500,
    //         color: '#1F303D'
    //       }
    //     },
    //     yAxis: {
    //       title: {
    //         text: `Usage (${this.type === 'water' ? 'm3' : 'kWh'})`
    //       },
    //       min: 0
    //     },
    //     tooltip: {
    //       pointFormat: `{point.y:.4f} ${this.type === 'water' ? 'm3' : 'kWh'}`
    //     },
    //     series,
    //     xAxis: {
    //       type: 'datetime',
    //       dateTimeLabelFormats: {
    //         month: '%b',
    //         year: '%b'
    //       },
    //       title: {
    //         text: 'Date'
    //       }
    //     }
    //   };
    // }
  }
};
</script>

<style lang="scss">
.placeholder {
  background-color: #4e545c;
  font-size: 0 !important;
  line-height: 0 !important;
  border-radius: 4px;
  margin-bottom: 1px;
  min-height: 24px;

  animation: fading 1.5s infinite;

  &.placeholder-inline {
    display: inline-block;
    width: 100px;

    &.placeholder-sm {
      width: 60px;
    }

    &.placeholder-lg {
      width: 140px;
    }

    .font-size-h1,
    .font-size-h2,
    .font-size-h3,
    .font-size-h4,
    .font-size-h5 {
      font-size: 0 !important;
    }
  }
}

@keyframes fading {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.1;
  }
}
</style>
