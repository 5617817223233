<template>
  <div>
    <apexchart class="mb-4" type="bar" height="350" :options="chartOptions" :series="consumptionByCategory" />

    <div class="widget-grid mb-5">
      <div class="info-box d-flex align-items-center px-4 py-3">
        <div class="flex-grow-1">
          <p class="font-size-h2 mb-1">{{ categorySummary.landfill | numberFormat(2) }} {{ units }}</p>
          <p class="text-muted font-w600 mb-0">Total waste to landfill</p>
          <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
        </div>
        <i class="fad text-warning fa-dumpster fa-2x ml-2"></i>
      </div>

      <div class="info-box d-flex align-items-center px-4 py-3">
        <div class="flex-grow-1">
          <p class="font-size-h2 mb-1">{{ categorySummary.recyclables | numberFormat(2) }} {{ units }}</p>
          <p class="text-muted font-w600 mb-0">Total waste recycled</p>
          <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
        </div>
        <i class="fad text-info fa-recycle fa-2x ml-2"></i>
      </div>

      <div class="info-box d-flex align-items-center px-4 py-3">
        <div class="flex-grow-1">
          <p class="font-size-h2 mb-1">{{ categorySummary.compost | numberFormat(2) }} {{ units }}</p>
          <p class="text-muted font-w600 mb-0">Total waste composted</p>
          <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
        </div>
        <i class="fad text-success fa-apple-core fa-2x ml-2"></i>
      </div>

      <div class="info-box d-flex align-items-center px-4 py-3">
        <div class="flex-grow-1">
          <p class="font-size-h2 mb-1">{{ categorySummary.efw | numberFormat(2) }} {{ units }}</p>
          <p class="text-muted font-w600 mb-0">Total waste EfW</p>
          <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
        </div>
        <i class="fad text-danger fa-fire fa-2x ml-2"></i>
      </div>

      <div class="info-box d-flex align-items-center px-4 py-3">
        <div class="flex-grow-1">
          <p class="font-size-h2 mb-1">{{ categorySummary.total | numberFormat(2) }} {{ units }}</p>
          <p class="text-muted font-w600 mb-0">Total waste</p>
          <p class="text-muted mb-0">{{ startDate.format('Do MMM YYYY') }} to {{ endDate.format('Do MMM YYYY') }}</p>
        </div>
        <i class="fad text-success fa-2x ml-2"></i>
      </div>
    </div>

    <SectionTitle class="mb-5">Summary Table</SectionTitle>

    <div class="row">
      <div class="col-xl-6">
        <table class="table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="category in consumptionByCategory" :key="category.name">
              <td>{{ category.name }}</td>
              <td>{{ category.data.reduce((sum, v) => sum + v, 0) | numberFormat(2) }} {{ units }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <th>{{ categorySummary.total | numberFormat(2) }} {{ units }}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

import { colours } from '@/lib/chartHelpers';

import SectionTitle from '@/components/base/SectionTitle';

export default {
  name: 'WasteSummary',
  components: {
    SectionTitle
  },
  props: {
    consumptions: {
      type: Array,
      required: true
    },
    account: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      justified: false,
      units: 'kg',
      landfillCategories: ['general waste', 'general bulky'],
      efwCategories: ['general waste efw', 'general bulky efw'],
      recycleCategories: ['mixed recyclables', 'weee', 'batteries', 'confi. shredding', 'glass'],
      compostCategories: ['food']
    };
  },
  computed: {
    months() {
      return Array.from(new Set(this.sortedConsumption.map(c => moment(c.timestamp).format('MMM YYYY'))));
    },
    startDate() {
      return moment(this.options.filters.dateRange[0], 'DD/MM/YYYY');
    },
    endDate() {
      return moment(this.options.filters.dateRange[1], 'DD/MM/YYYY');
    },
    consumptionData() {
      return this.consumptions.map(c => [moment.utc(c.timestamp).valueOf(), c.value]);
    },
    sortedConsumption() {
      return [...this.consumptions].sort((a, b) => a.timestamp - b.timestamp);
    },
    consumptionByCategory() {
      const byCategory = this.sortedConsumption.reduce((acc, consumption) => {
        const category = consumption.category || 'Uncategorised';

        const existingCategory = acc.find(c => c.name === category);

        let value;

        if (this.units === 'kg' && consumption.unit === 'tonnes') {
          value = consumption.value * 1000;
        } else if (this.units === 'tonnes' && consumption.unit === 'kg') {
          value = consumption.value / 1000;
        } else {
          value = consumption.value;
        }

        const dateIndex = this.months.findIndex(date => date === moment(consumption.timestamp).format('MMM YYYY'));

        if (existingCategory) {
          existingCategory.data[dateIndex] = value;
        } else {
          const consumptionObject = {
            name: category,
            data: []
          };
          consumptionObject.data[dateIndex] = value;
          acc.push(consumptionObject);
        }

        return acc;
      }, []);

      return byCategory.filter(c => c.data.length > 0 && c.data.some(v => v > 0));
    },
    categorySummary() {
      const landfill = this.consumptionByCategory
        .filter(c => this.landfillCategories.includes(c.name.toLowerCase()))
        .reduce((sum, cat) => sum + cat.data.reduce((s, v) => s + v, 0), 0);

      const efw = this.consumptionByCategory
        .filter(c => this.efwCategories.includes(c.name.toLowerCase()))
        .reduce((sum, cat) => sum + cat.data.reduce((s, v) => s + v, 0), 0);

      const recyclables = this.consumptionByCategory
        .filter(c => this.recycleCategories.includes(c.name.toLowerCase()))
        .reduce((sum, cat) => sum + cat.data.reduce((s, v) => s + v, 0), 0);

      const compost = this.consumptionByCategory
        .filter(c => this.compostCategories.includes(c.name.toLowerCase()))
        .reduce((sum, cat) => sum + cat.data.reduce((s, v) => s + v, 0), 0);

      const total = this.consumptionByCategory.reduce((sum, cat) => sum + cat.data.reduce((s, v) => s + v, 0), 0);

      return {
        total,
        landfill,
        efw,
        recyclables,
        compost
      };
    },
    categories() {
      const cleanCategories = {};

      const allCategories = [...this.consumptions].map(c => c.category).sort((a, b) => a.localeCompare(b));

      allCategories.forEach(category => {
        cleanCategories[category] = true;
      });

      return Object.keys(cleanCategories);
    },
    title() {
      const isOneDay = moment(this.startDate).isSame(this.endDate, 'day');

      let title = `Waste consumption (${this.units}) `;

      if (isOneDay) {
        title += ` on ${this.startDate.format('Do MMM YYYY')}`;
      } else {
        title += ` from ${this.startDate.format('Do MMM YYYY')} to ${this.endDate.format('Do MMM YYYY')}`;
      }

      const subMeters = this.account.parentAccountId ? [this.account] : this.account.childAccounts;

      if (this.options.filters.subMeterId) {
        title += ` for ${(subMeters || []).find(sm => sm._id === this.options.filters.subMeterId).name}`;
      }

      return title;
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          ...(this.justified ? { stackType: '100%' } : {})
        },
        dataLabels: {
          formatter: val => {
            return `${val.toLocaleString(this.$i18n?.locale || 'en', { maximumFractionDigits: 2 })} ${this.units}`;
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              total: {
                enabled: this.categories.length > 1,
                offsetY: -5,
                formatter: val => {
                  return `${val.toLocaleString(this.$i18n?.locale || 'en', { maximumFractionDigits: 2 })} ${this.units}`;
                },
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          }
        },
        title: {
          text: this.title,
          align: 'center',
          style: {
            fontSize: '18px',
            fontWeight: 600
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          // Array of months
          categories: this.months,
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 600
            }
          },
          decimalsInFloat: 2
        },
        yaxis: {
          title: {
            text: undefined
          },
          labels: {
            formatter: function (val) {
              return val;
            },
            style: {
              fontSize: '12px',
              fontWeight: 600
            }
          }
        },
        tooltip: {
          y: {
            formatter: val => {
              return `${val.toLocaleString(this.$i18n?.locale || 'en', { maximumFractionDigits: 2 })} ${this.units}`;
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          itemMargin: {
            vertical: 15
          },
          fontSize: '12px',
          fontWeight: 600,
          markers: {
            offsetX: -2
          }
        },
        colors: colours
      };
    }
  },
  mounted() {
    if (this.consumptions.some(c => c.unit === 'tonnes')) {
      this.units = 'tonnes';
    }
  }
};
</script>
